import { graphql } from 'gatsby'
import * as React from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'

import Footer from '../components/Footer'
import SiteNav from '../components/header/SiteNav'
import PostCard from '../components/PostCard'
import Wrapper from '../components/Wrapper'
import IndexLayout from '../layouts'
import SEO from '../components/header/SEO'
import config from '../website-config'
import {
  inner,
  outer,
  PostFeed,
  PostFeedRaise,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
} from '../styles/shared'

const HomePosts = css`
  @media (min-width: 795px) {
    .post-card:nth-of-type(6n + 1):not(.no-image) {
      flex: 1 1 100%;
      flex-direction: row;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      border-radius: 5px 0 0 5px;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-content {
      flex: 0 1 357px;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) h2 {
      font-size: 2.6rem;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) p {
      font-size: 1.8rem;
      line-height: 1.55em;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-content-link {
      padding: 30px 40px 0;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-meta {
      padding: 0 40px 30px;
    }
  }
`

const IndexPage = ({ data }) => {
  const width = data.header.fluid.sizes.split(', ')[1].split('px')[0]
  const height = String(Number(width) / data.header.fluid.aspectRatio)
  return (
    <IndexLayout css={HomePosts}>
      <SEO />
      <Wrapper>
        <header
          css={[outer, SiteHeader]}
          style={{
            backgroundImage: `url('${data.header.fluid.src}')`,
          }}
        >
          <div css={inner}>
            <SiteHeaderContent>
              <SiteTitle>{config.title}</SiteTitle>
            </SiteHeaderContent>
            <SiteNav isHome />
          </div>
        </header>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <div css={[PostFeed, PostFeedRaise]}>
              {data.allContentfulPost.edges.map(post => (
                // filter out drafts in production
                <PostCard key={post.node.slug} post={post.node} />
              ))}
            </div>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  query {
    header: contentfulAsset(contentful_id: { eq: "1O2kBOhTCgwAzDQt7DuxTS" }) {
      title
      fluid(maxWidth: 2000) {
        aspectRatio
        base64
        sizes
        src
        srcSet
        srcSetWebp
        srcWebp
      }
    }
    allContentfulPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          heroImage {
            fluid(maxWidth: 3200) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          publishDate
          tags
          body {
            childMarkdownRemark {
              excerpt
              timeToRead
            }
          }
          author {
            name
            image {
              fluid(maxWidth: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
